(function example(document) {
  'use strict';

  var videoContainer = document.querySelectorAll('[data-videoslider]');
  
  function init() {
    videoContainer.forEach(function(el) {
      var currentStep = 0;
      var intSpeed = 75;
      var timeSpeed = .075;
      var playStepForward = false;
      var playStepBackward = false;
      var video = el.querySelector('[data-videoslider-video]');
      var range = el.querySelector('[data-video="range"]');
      var playBtn = el.querySelector('[data-videoslider-play]');
      var playReverseBtn = el.querySelector('[data-videoslider-play-reverse]');
      var playNextBtn = el.querySelector('[data-videoslider-step-next]');
      var playPrevBtn = el.querySelector('[data-videoslider-step-prev]');
      var tweenForwardBtn = el.querySelector('[data-videoslider-tween-forward]');
      var tweenBackwardBtn = el.querySelector('[data-videoslider-tween-backward]');
      var resetBtn = el.querySelector('[data-videoslider-reset]');

      range.addEventListener('input', function(e) {
        const { value } = event.target;        
        var videoTime = video.duration * parseFloat(value)
        video.currentTime = videoTime;
      });

      /* video.addEventListener('timeupdate', function(e) {
        var snapOffset = 0.15;
        console.log('----');
        console.log(e.target.currentTime);
        console.log(e.target.currentTime + snapOffset);
        console.log(currentStep);
        
        if (playStepForward && (e.target.currentTime + snapOffset) >= currentStep) {
          e.target.pause();
          playStepForward = false;
          e.target.currentTime = currentStep;
        }

        if (playStepBackward && (e.target.currentTime + snapOffset) <= currentStep) {
          e.target.pause();
          playStepBackward = false;
          e.target.currentTime = currentStep;
        }
      }); */

      /* function videoPlay(video, videostart) {
        var start = videostart;
        var element = video;
        var duration = video.duration * 1000; // in ms

        console.log(duration);

        function step(timestamp) {
          if (!start) start = timestamp;
          var progress = timestamp - start;
          
          // element.style.transform = 'translateX(' + Math.min(progress / 10, 200) + 'px)';
          element.currentTime = Math.min(progress / 1000, 200);
          console.log(Math.min(progress / 1000, 200));

          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        }

        window.requestAnimationFrame(step);
      } */

      playBtn.addEventListener('click', function(e) {
        e.preventDefault();
        currentStep = 3;
        // video.play();

        var intervalRewind;
        intervalRewind = setInterval(function() {
          if(video.currentTime == 3){
            clearInterval(intervalRewind);
            video.pause();
            currentStep = 3;
          } else{
            video.currentTime += timeSpeed;
          }
        }, intSpeed); // 2fps
      });

      playReverseBtn.addEventListener('click', function(e) {
        e.preventDefault();

        var intervalRewind;
        intervalRewind = setInterval(function() {
          if(video.currentTime == 0){
            clearInterval(intervalRewind);
            video.pause();
            currentStep = 0;
          } else{
            video.currentTime += -timeSpeed;
          }
        }, intSpeed); // 2fps
      });

      playNextBtn.addEventListener('click', function(e) {
        e.preventDefault();
        playStepForward = true;
        currentStep += 1;
        // video.play();

        var intervalRewind;
        intervalRewind = setInterval(function() {
          if(video.currentTime == 3 || video.currentTime >= currentStep){
            clearInterval(intervalRewind);
            video.pause();
            video.currentTime = currentStep;
          } else{
            video.currentTime += timeSpeed;
          }
        }, intSpeed); // 2fps
      });

      playPrevBtn.addEventListener('click', function(e) {
        e.preventDefault();
        playStepBackward = true;
        currentStep -= 1;

        var intervalRewind;
        intervalRewind = setInterval(function() {
          if(video.currentTime == 0 || video.currentTime <= currentStep){
            clearInterval(intervalRewind);
            video.pause();
            video.currentTime = currentStep;
          } else{
            video.currentTime += -timeSpeed;
          }
        }, intSpeed); // 2fps
      });

      tweenForwardBtn.addEventListener('click', function(e) {
        e.preventDefault();
        currentStep += 1;
        TweenMax.to(video, 1,{currentTime: currentStep, ease:Linear.easeNone});
      });

      tweenBackwardBtn.addEventListener('click', function(e) {
        e.preventDefault();
        currentStep -= 1;
        TweenMax.to(video, 1,{currentTime: currentStep, ease:Linear.easeNone});
      });

      resetBtn.addEventListener('click', function(e) {
        e.preventDefault();
        video.currentTime = 0;
        currentStep = 0;
        playStepForward = false;
        playStepBackward = false;
      });

    });
  }

  init();
}(document));

(function(window, document) {

  var playBtn = document.querySelector('#play');
  var reverseBtn = document.querySelector('#reverse');
  var resetBtn = document.querySelector('#reset');
  
  var canvidCtrl = document.querySelectorAll('.canvid-ctrl');

  var playVid = function() {
    var lastFrame = canvidControl.getCurrentFrame()
    if(lastFrame >= 75) {
      lastFrame = 0;
    }
    
    var intervalRewind;
    intervalRewind = setInterval(function() {
      currentFrame = canvidControl.getCurrentFrame();
      if(currentFrame === lastFrame + 25 || currentFrame >= 75) {
        clearInterval(intervalRewind);
        canvidControl.pause();
      }
    }, 25);
    
    canvidControl.play('canvidExample');
    canvidControl.setCurrentFrame(lastFrame);
  };

  var reverseVid = function() {
    var lastFrame = canvidControl.getCurrentFrame()
    if(lastFrame <= 0) {
      lastFrame = 75;
    }    
    var intervalRewind;
    intervalRewind = setInterval(function() {
      currentFrame = canvidControl.getCurrentFrame();
      if(currentFrame === lastFrame - 25 || currentFrame <= 0) {
        clearInterval(intervalRewind);
        canvidControl.pause();
      }
    }, 25);

    canvidControl.play('canvidExample', true);
    canvidControl.setCurrentFrame(lastFrame);
  };

  var resetVid = function() {
    canvidControl.pause();
    canvidControl.setCurrentFrame(0);
  };

  var init = function() {
    canvidControl.pause();
    canvidControl.setCurrentFrame(0);
  }

  var canvidControl = canvid({
      selector: '#canvid-example',
      videos: {
          canvidExample: {
              src: 'images/myvideo.jpg',
              frames: 76,
              cols: 6
          }
      },
      width: 1180,
      height: 590,
      loaded: function(e) {
        setTimeout(function() {
          init(e);
        }, 25);
        canvidControl.play('canvidExample');
      }      
  });

  playBtn.addEventListener('click', playVid);
  reverseBtn.addEventListener('click', reverseVid);
  resetBtn.addEventListener('click', resetVid);

}(window, document));